@use "../global" as *;

.pick-section {
  padding: 10rem 0;
}

.pick-container {
  display: flex;
  flex-direction: column;

  &__title {
    margin: 0 auto;
    text-align: center;
    color: $text-black;
    max-width: 50rem;
    margin-bottom: 5rem;

    p {
      font-size: $p-size;
      font-family: $text-font;
      color: $text-gray;
      line-height: 1.5;
    }

    h3 {
      font-size: $h3-size;
      font-family: $text-font;
      font-weight: 500;
    }

    h2 {
      font-size: $h2-size;
      font-family: $titles-font;
      margin: 0.5rem 0 1rem 0;
    }
  }

  &__car-content {
    display: flex;
    justify-content: space-between;
    gap: 1rem;

    @media (max-width: 1050px) {
      flex-direction: column;
      gap: 5rem;
    }
  }
}

.pick-box {
  display: flex;
  flex-direction: column;
  gap: 0.7rem;

  button {
    font-size: 2rem;
    font-family: $titles-font;
    font-weight: 600;
    border: none;
    cursor: pointer;
    padding: 1.5rem 2.5rem;
    background-color: #e9e9e9;
    transition: all 0.2s;
    text-align: left;

    &:hover {
      background-color: $text-orange;
      color: white;
    }
  }
}

.pick-car {
  width: 50rem;
  position: relative;

  @media (max-width: 700px) {
    width: 100%;
  }

  img {
    width: 100%;
    margin-top: 6rem;
  }
}

.pick-description {
  width: 25rem;

  &__price {
    width: 100%;
    background-color: $text-orange;
    font-size: 1.8rem;
    display: flex;
    align-items: center;
    gap: 1.5rem;
    color: white;
    padding: 0.3rem 1.9rem;
    white-space: nowrap;
    justify-content: center;

    span {
      font-size: 2.8rem;
      font-weight: 700;
    }
  }

  &__table {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    font-size: 1.4rem;
    // font-family: $text-font;

    &__col {
      display: grid;
      grid-template-columns: 1fr 1fr;
      text-align: center;
      grid-template-rows: auto;
      padding: 0.9rem 0.5rem;
      border-bottom: 2px solid #706f7b;
      border-right: 2px solid #706f7b;
      border-left: 2px solid #706f7b;

      span:nth-child(1) {
        border-right: 2px solid #706f7b;
      }
    }
  }
}

.cta-btn {
  font-size: 2rem;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: 600;
  color: white;
  font-family: $titles-font;
  background-color: $text-orange;
  padding: 1rem 1rem;
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 1.4rem;
  transition: all 0.3s;
  box-shadow: 6px 6px 0 #efe9e9;

  &:hover {
    background-color: #e9381d;
  }
}

.box-cars {
  gap: 11rem;
  display: flex;
  justify-content: space-between;

  @media (max-width: 1200px) {
    gap: 2rem;
  }

  @media (max-width: 700px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5rem;
  }
}

.colored-button {
  background-color: $text-orange !important;
  color: white;
}

// spiner
.loader {
  width: 48px;
  height: 48px;
  border: 5px solid #00000042;
  border-bottom-color: #ff3d00;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 40%;
  right: 43%;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
